// Navbar section

import { scrollEventThrottle } from '../utils';


document.addEventListener('DOMContentLoaded', function() {
    // Fixed navbar : add `scrolled` class to change styles on scroll
    // See commit 6ffe23f0919969fadcf9c83f04753668c7492c5e when the clean and modern solution with Intersection Observer
    // was removed because of lack of good support on Safari. Maybe the support will be good some day...
    scrollEventThrottle((scrollPos, previousScrollPos) => {
        var logoNavContainer = document.querySelector('#logo-nav-container');
        if (window.pageYOffset >= logoNavContainer.getBoundingClientRect().height / 2) {
            logoNavContainer.classList.add('scrolled');
        } else {
            logoNavContainer.classList.remove('scrolled');
        }
    });
});
