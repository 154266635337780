// Expertise section

import { Splide } from '@splidejs/splide';

import { allowSidewaysScroll } from '../utils';


document.addEventListener('DOMContentLoaded', function() {
    // -- Expertise carousel
    var expertiseCarouselEl = document.querySelectorAll('.expertise-carousel')[0];
    var expertiseCarousel = new Splide(expertiseCarouselEl, {
        perPage: 3,
        perMove: 1,
        pagination: false,
        drag: true,
        // type: 'loop', # cant use loop mode, splide clones the cards and ids for checkboxes are duplicated
        gap: '2rem',
        breakpoints: {
            576: {
                padding: '5rem',
                perPage: 1
            },
            // 767: {
            // },
            959: {
                pagination: true
            },
            // 1199: {
            // },
            1650: {
                perPage: 2
            }
        }

    }).mount();

    allowSidewaysScroll(expertiseCarouselEl, expertiseCarousel);
});
