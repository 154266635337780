// Util functions

// debounce util function
export function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}


// Throttle scroll event
export function scrollEventThrottle(fn) {
    let last_known_scroll_position = 0;
    let ticking = false;
    window.addEventListener('scroll', function () {
        let previous_known_scroll_position = last_known_scroll_position;
        last_known_scroll_position = window.scrollY;
        if (!ticking) {
            window.requestAnimationFrame(function () {
                fn(last_known_scroll_position, previous_known_scroll_position);
                ticking = false;
            });
            ticking = true;
        }
    });
}


export function allowSidewaysScroll(element, splide) {
    let debounceDelay = 250;
    let lastScrollTime = Date.now();

    //Add horizontal scrolling support to splide sliders
    element.addEventListener('wheel', function (event) {
        if (event.ctrlKey) {
            return; // Exit the function if Ctrl key is held
        }

        let deltaX = event.deltaX;
        let deltaY = event.deltaY;

        //Windows shift-scroll
        if (event.shiftKey && Math.abs(deltaX) === 0) {
            event.preventDefault();

            if (deltaY > 0) {
                splide.go('+1');

            } else if (deltaY < 0) {
                splide.go('-1');
            }
        }

        //Mac sideways touch scroll
        if (!event.shiftKey && Math.abs(deltaX) !== 0) {
            if (Math.abs(event.deltaX) < Math.abs(event.deltaY)) {
                // Scrolling more vertically than horizontally. Let it be!
                return;
            }

            const scrollLeftMax = element.scrollWidth - element.offsetWidth;

            if (
                element.scrollLeft + event.deltaX < 0 ||
                element.scrollLeft + event.deltaX > scrollLeftMax
            ) {
                //Stop backwards nagivation on sideways scroll on element
                event.preventDefault();
            }

            let currentTime = Date.now();
            let timeElapsed = currentTime - lastScrollTime;

            //Allow no delay on first scroll of slider per second
            if (timeElapsed > debounceDelay) {
                if (deltaX > 0) {
                    splide.go('+1');
                } else if (deltaX < 0) {
                    splide.go('-1');
                }

                lastScrollTime = currentTime;
            } else {
                //Debounce scrolling to avoid scrolling to the end of the slider on first scroll
                if (timeElapsed >= debounceDelay) {

                    if (deltaX > 3) {
                        splide.go('+1');
                    } else if (deltaX < -3) {
                        splide.go('-1');
                    }

                    lastScrollTime = currentTime;
                }
            }
        }
    }, false);
}
