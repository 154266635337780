// Team section

import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

import { allowSidewaysScroll } from '../utils';


document.addEventListener('DOMContentLoaded', function() {
    // -- Team carousel
    var teamCarouselEl = document.querySelectorAll('.team-carousel')[0];
    var teamCarousel = new Splide(teamCarouselEl, {
        perMove: 1,
        pagination: true,
        arrows: false,
        drag: true,
        autoWidth: true,
        type: 'loop',
        pauseOnHover: false,
        pauseOnFocus: false,
        autoScroll: {
            speed: -1
        },
        mediaQuery: 'min'
    }).mount({ AutoScroll });

    if (teamCarousel.length < 4) {
        switch (teamCarousel.length) {
        case 3:
            teamCarousel.options = {
                breakpoints: {
                    1420: {
                            autoScroll: false,
                            type: 'default',  // Note : requires a reload if the user resizes the screen (same bellow).
                            drag: false,
                            pagination: false
                        }
                }
            };
            break;
        case 2:
            teamCarousel.options = {
                breakpoints: {
                    959: {
                            autoScroll: false,
                            type: 'default',
                            drag: false,
                            pagination: false
                        }
                }
            };
            break;
        case 1:
            teamCarousel.options = {
                autoScroll: false,
                type: 'default',
                drag: false,
                pagination: false
            };
            break;
        }
        teamCarousel.destroy().mount();
    }

    allowSidewaysScroll(teamCarouselEl, teamCarousel);
    window.dispatchEvent(new CustomEvent('scroll'));
});
