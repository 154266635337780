// Main JS shared by all pages

import { scrollEventThrottle } from './utils';

document.addEventListener('DOMContentLoaded', function() {
    // Variables
    var returnTopBtn = document.querySelector('.return-page-top');
    var topButtonVisible = false;

    // -- Scroll event
    scrollEventThrottle((scrollPos, previousScrollPos) => {
        var showAfter = 100;
        var canBeVisible = scrollPos > showAfter;
        var scrollingUp = previousScrollPos > scrollPos;
        var shouldBeVisible = canBeVisible && scrollingUp;

        if (!topButtonVisible && shouldBeVisible) {
            returnTopBtn.style.opacity = 100;
            topButtonVisible = true;
        } else if (topButtonVisible && !shouldBeVisible) {
            returnTopBtn.style.opacity = 0;
            topButtonVisible = false;
        }
    });
    window.dispatchEvent(new CustomEvent('scroll'));

    // -- Return page top
    returnTopBtn.addEventListener('click', function(){
        window.scrollTo({top: 0, behavior: 'smooth'});
        history.replaceState(null, null, window.location.pathname);
    });
});
