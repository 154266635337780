// Contact section

import L from './leaflet_grayscale';
import { GestureHandling } from 'leaflet-gesture-handling';

const axios = require('axios');
L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);


document.addEventListener('DOMContentLoaded', function() {
    // --- Contact form ---
    var contactForm = document.querySelector('#contact-form');
    if (contactForm) {
        document.querySelectorAll('#contact-form input').forEach(input => {
            ['click', 'keypress', 'change'].forEach(evt => {
                input.addEventListener(evt, function() {
                    if (!contactInitTime) contactInitTime = new Date();
                });
            });
        });

        // HP
        setTimeout(function() {
            document.querySelector('#terms').setAttribute('aria-required', 'false');
            document.querySelector('#terms-label').setAttribute('aria-required', 'false');
        }, 5000);

        var contactFormButton = document.querySelector('#contact-form .send-button');
        var contactInitTime = null;

        contactForm.addEventListener('submit', function (event) {
            event.preventDefault();

            const formAxios = axios.create();
            formAxios.interceptors.request.use(config => {
                contactFormButton.setAttribute('disabled', 'disabled');
                contactFormButton.setAttribute('aria-disabled', true);
                return config;
            });

            var body = new FormData(contactForm);
            body.append('time', new Date() - contactInitTime);

            function updateValidationMessages(data, request) {
                ['name', 'email', 'phone', 'message'].forEach(id => {
                    var input = contactForm.querySelector('#' + id);
                    var errorSpan = input.nextElementSibling;
                    if (data && id in data) {
                        input.setAttribute('aria-invalid', true);
                        input.classList.add('error');
                        errorSpan.innerHTML = request.response.data[id][0];
                    } else {
                        input.setAttribute('aria-invalid', false);
                        input.classList.remove('error');
                        errorSpan.innerHTML = '';
                    }
                });
            }

            formAxios.post(contactForm.getAttribute('action'), body)
                .then(function (request) {
                    updateValidationMessages(null, request);
                    contactFormButton.innerHTML = contactFormButton.dataset.submitMessageSent;
                })
                .catch(function (request) {
                    if(request.response.data) {
                        updateValidationMessages(request.response.data, request);
                        contactFormButton.innerHTML = contactFormButton.dataset.submitMessageFormError;
                        contactFormButton.removeAttribute('disabled');
                        contactFormButton.setAttribute('aria-disabled', false);
                    } else {
                        contactFormButton.innerHTML = contactFormButton.dataset.submitMessageError;
                    }
                });
        });
    }


    // -- Contact section map
    const coords = [46.73239366822634, -71.26754080256761];
    const zoomLevel = 16;

    var contactMap = L.map('contact-map', {
        gestureHandling: true
    }).setView(coords, zoomLevel);

    L.tileLayer.grayscale('https://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(contactMap);

    // Marker
    var icon = L.icon({
        iconUrl: '/static/images/map-pin.svg',

        iconSize:     [40, 50],
        iconAnchor:   [20, 50],
        popupAnchor:  [0, -50]
    });

    var marker = L.marker(coords, {icon: icon}).addTo(contactMap);

    marker.bindPopup(`
        <div class="map-popup">
            <h4>${gettext('Our office location')}</h4>
            <a href="http://www.google.com/maps/place/${coords[0]},${coords[1]}" target="_blank">
                ${gettext('Follow this link to get directions')}
            </a>
        </div>
    `);
});
