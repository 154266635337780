// JS for the index page

import './main';
import './partials/contact';
import './partials/navbar';
import './partials/expertise';
import './partials/team';

document.addEventListener('DOMContentLoaded', function() {
    // Click of mobile menu links
    document.querySelectorAll('.nav-item:not(.nav-item-mobile) a').forEach(function (navLink) {
        navLink.addEventListener('click', function () {
            document.getElementById('hamburger-toggle').checked = false;
        });
    });
});
